<template>
  <div class="text-teal text-lg font-bold pl-4 pb-3 mt-2 ">
    <router-link :to="{ name: route }">
      <p class="">{{ title }}</p>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    route: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
