<template>
  <div class="border-2 rounded-xl bg-lightGrey my-1 w-11.5/12 m-auto h-14">
    <ul class="flex justify-between h-full flex-wrap content-center ml-10">
      <div class="flex flex-nowrap">
        <span class="material-icons mr-1 text-red">
          picture_as_pdf
        </span>
        <li>
          <router-link
            :to="{ name: 'cs-policy', params: { policyId: policyData.id } }"
          >
            {{ policyData.name }}</router-link
          >
        </li>
      </div>
      <li class="mr-20">{{ convertedTime(policyData.created_at) }}</li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    policyData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
};
</script>
