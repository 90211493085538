<template>
  <!-- Content -->
  <div
    class="flex justify-between w-full min-h-5 border-b-2 rounded-sm py-2 ml-1 px-3"
  >
    <!-- Left Side -->
    <div class="w-5/12">
      <div class="sm:flex h-full content-center">
        <!-- Icon -->
        <div class="hidden sm:flex flex-wrap content-center ml-1 mr-1">
          <div class="rounded-full h-12 w-12  flex items-center justify-center">
            <!-- <img
                    v-if="!imageError"
                    alt="Profile"
                    @error="setAltImg"
                    :src="profileData.profile.profile_image"
                    class="mx-auto object-cover rounded-full w-full h-full "
                  /> -->
            <img
              alt="Profile"
              src="../../../assets/1.png"
              class="mx-auto object-cover rounded-full w-full h-full "
            />
          </div>
        </div>
        <!-- Thread Title + Post Info -->
        <div
          class=" lg:flex-row lg:h-full flex-col flex-wrap content-center pl-2 pt-2"
        >
          <div class="flex">
            <router-link
              :to="{
                name: 'cs-communicationBookItem',
                params: { threadId: postData.id },
              }"
            >
              <strong>{{ postData.title }}</strong></router-link
            >
          </div>

          <div class="hidden sm:block">
            <p>
              <span class="hidden text-darkGrey text-sm  sm:block"
                >{{ postData.user.email }} -
                {{ convertedTime(postData.created_at) }}</span
              >
            </p>
            <span
              v-if="postData.post_attachments.length"
              class="w-full flex items-center py-0.1 rounded-full text-darkGrey"
            >
              <span
                v-for="(attachment, index) in postData.post_attachments"
                :key="attachment.id"
                ><a :href="attachment.url" target="_blank"
                  ><span class="material-icons text-sm"> description </span
                  >File{{ index + 1 }}</a
                ></span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- Read/Views -->
    <div class="flex flex-wrap content-center w-2/12">
      <div class="flex-row m-auto">
        <!-- Replies -->
        <div class="py-1">
          <p class="text-DarkGrey">Replies: {{ postData.answers_count }}</p>
        </div>
        <!-- Views -->
        <div class="py-1">
          <p class="text-DarkGrey">Views: {{ postData.post_views_count }}</p>
        </div>
      </div>
    </div>
    <!-- Right Side -->
    <div class="flex justify-end w-5/12">
      <!-- Anwser Time + User Info -->
      <div class="flex flex-wrap content-center ml-2">
        <div class="justify-end">
          <div class="">
            <p v-if="postData.answers.length > 0">
              {{
                convertedTime(
                  postData.answers[postData.answers.length - 1].created_at
                )
              }}
            </p>
            <p v-else>
              {{ convertedTime(postData.created_at) }}
            </p>
          </div>
          <div class="">
            <p v-if="postData.answers.length > 0">
              <span class="text-darkGrey text-sm text">{{
                postData.answers[postData.answers.length - 1].user.email
              }}</span>
            </p>
            <p v-else>
              <span class="text-darkGrey text-sm">{{
                postData.user.email
              }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- Icon -->
      <div class="hidden sm:flex flex-wrap content-center m-2">
        <div
          v-if="postData.answers.length > 0"
          class="rounded-full h-16 w-16  flex items-center justify-center"
        >
          <!-- <img
                    v-if="!imageError"
                    alt="Profile"
                    @error="setAltImg"
                    :src="profileData.profile.profile_image"
                    class="mx-auto object-cover rounded-full w-full h-full "
                  /> -->
          <img
            alt="Profile"
            src="../../../assets/1.png"
            class="mx-auto object-cover rounded-full w-full h-full "
          />
        </div>
        <!-- No responses -->
        <div
          v-else
          class="rounded-full h-16 w-16  flex items-center justify-center"
        >
          <!-- <img
                    v-if="!imageError"
                    alt="Profile"
                    @error="setAltImg"
                    :src="profileData.profile.profile_image"
                    class="mx-auto object-cover rounded-full w-full h-full "
                  /> -->
          <img
            alt="Profile"
            src="../../../assets/1.png"
            class="mx-auto object-cover rounded-full w-full h-full "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    postData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    getInitials(name) {
      let parts = name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      return initials;
    },
  },

  computed: {},
};
</script>
