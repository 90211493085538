<template>
  <!-- Card Title -->
  <div class="flex flex-nowrap text-teal">
    <div class="flex justify-start items-center flex-1 pl-2">
      <slot></slot>
    </div>
    <div class="flex items-center mx-4">
      <router-link :to="{ name: 'cs-signatureList' }">
        <span class="material-icons text-2xl cursor-pointer">
          grading
        </span>
      </router-link>
    </div>
  </div>
  <div v-if="!isLoading">
    <!-- Items -->
    <dashboard-policy-item
      v-for="policy in getPolicies"
      :key="policy.id"
      :policyData="policy"
    >
    </dashboard-policy-item>
  </div>
  <div v-else>
    <heart-rate fast="true"></heart-rate>
  </div>
  <br />
  <!-- Pagination -->
  <my-pagination
    v-if="!!getPolicies.total"
    :options="options"
    v-model="page"
    :records="getPolicies.total"
    :per-page="getPolicies.per_page"
    @paginate="myCallback"
  >
  </my-pagination>
  <div v-else>
    <blank-paginate> </blank-paginate>
  </div>
</template>

<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";

import dashboardPolicyItem from "./dashboardPolicyItem.vue";
export default {
  components: { dashboardPolicyItem, blankPaginate },
  created() {
    this.loadPolicies(this.page);
  },
  data() {
    return {
      isLoading: false,
      hideModal: true,
      page: 1,
      options: {
        template: markRaw(customPaginate),
      },
    };
  },
  methods: {
    myCallback(e) {
      this.loadPolicies(e);
    },
    async loadPolicies(page) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("cleaner/loadPolicies", page);
        this.isLoading = false;
      } catch (error) {
        this.$toast.error("Something went wrong!");
      }
    },
  },
  computed: {
    getPolicies() {
      return this.$store.getters["cleaner/getPolicies"];
    },
  },
};
</script>
