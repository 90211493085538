<template>
  <main-card-header title="Dashboard Cleaner"></main-card-header>
  <div class="w-11.5/12 m-auto">
    <div class="flex flex-col justify-center xl:flex-row xl:justify-evenly">
      <div class="xl:w-1/2 mx-1">
        <div class="border-2 rounded-xl my-2 shadow-xl min-h-cardH">
          <dashboard-card-title
            route="cs-rooms"
            title="Practice Facilities & Cleaning Checklists"
          >
          </dashboard-card-title>
          <practice-facilities-checklist-card />
        </div>
        <div class="border-2 rounded-xl my-2 shadow-xl min-h-cardH">
          <policies-card>
            <dashboard-card-title route="cs-policies" title="Policies" />
          </policies-card>
        </div>
      </div>
      <div class="xl:w-1/2 mx-1">
        <div class="border-2 rounded-xl my-2 shadow-xl min-h-cardH">
          <communication-book-card>
            <dashboard-card-title
              route="cs-communicationBook"
              title="Communication Book (Forum)"
            />
          </communication-book-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dashboardCardTitle from "@/components/layout/CS/dashboardCardTitle.vue";
import practiceFacilitiesChecklistCard from "@/components/layout/CS/practiceFacilitiesChecklistCard.vue";
import policiesCard from "@/components/layout/CS/policiesCard.vue";
import communicationBookCard from "@/components/layout/CS/communicationBookCard.vue";
export default {
  components: {
    practiceFacilitiesChecklistCard,
    policiesCard,
    communicationBookCard,
    dashboardCardTitle,
  },
};
</script>
