<template>
  <div v-if="!isLoading">
    <!-- Table Header -->
    <div class="w-11.5/12 m-auto">
      <ul
        class="flex w-full justify-between border-b-2 border-ligthGrey text-darkGrey font-bold"
      >
        <li class="w-2/6 text-left pl-5">Area/Room</li>
        <li class="w-2/6 text-center">Cleaning Task List</li>
        <li class="w-2/6 text-right pr-5">Status</li>
      </ul>
    </div>

    <!-- Items -->
    <dashboard-room-item
      v-for="room in getRooms.data"
      :key="room.id"
      :roomData="room"
    >
    </dashboard-room-item>

    <br />
    <!-- Pagination -->
  </div>
  <div v-else>
    <heart-rate fast="true"></heart-rate>
  </div>
  <my-pagination
    v-if="!!getRooms.total"
    :options="options"
    v-model="page"
    :records="getRooms.total"
    :per-page="getRooms.per_page"
    @paginate="myCallback"
  >
  </my-pagination>
  <div v-else>
    <blank-paginate> </blank-paginate>
  </div>
</template>

<script>
import { markRaw } from "vue";
import dashboardRoomItem from "./dashboardRoomItem.vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";
export default {
  components: { dashboardRoomItem, blankPaginate },
  data() {
    return {
      isLoading: false,
      page: 1,
      options: {
        template: markRaw(customPaginate),
      },
    };
  },
  created() {
    this.loadRooms(this.page);
  },
  methods: {
    myCallback(e) {
      this.loadRooms(e);
    },
    async loadRooms(page) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("cleaner/loadRooms", page);
        this.isLoading = false;
      } catch (error) {
        this.$toast.error(`Something went wrong!`);
      }
    },
  },
  computed: {
    getRooms() {
      return this.$store.getters["cleaner/getRooms"];
    },
  },
};
</script>
