<template>
  <div class="flex flex-nowrap text-teal">
    <div class="flex justify-start items-center flex-1 pl-2">
      <slot></slot>
    </div>
    <div @click="toggleModal()" class="flex items-center justify-center mr-2">
      <p class="mr-2 text-darkGrey">Threads: {{ getPosts.total }}</p>
    </div>
  </div>
  <div v-if="!isLoading">
    <forum-item-dashboard
      v-for="post in getPosts.data"
      :key="post.id"
      :postData="post"
    ></forum-item-dashboard>

    <br />
  </div>
  <div v-else>
    <heart-rate fast="true"></heart-rate>
  </div>
  <my-pagination
    v-if="!!getPosts.total"
    :options="options"
    v-model="page"
    :records="getPosts.total"
    :per-page="getPosts.per_page"
    @paginate="myCallback"
  >
  </my-pagination>
  <div v-else>
    <blank-paginate> </blank-paginate>
  </div>
</template>

<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";
import forumItemDashboard from "./forumItemDashboard.vue";
export default {
  components: { forumItemDashboard, blankPaginate },
  created() {
    this.loadPosts(this.page);
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      options: {
        template: markRaw(customPaginate),
      },
    };
  },
  methods: {
    myCallback(e) {
      this.loadPosts(e);
    },
    async loadPosts(page) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("cleaner/loadPosts", page);
        this.isLoading = false;
      } catch (error) {
        this.$toast.error("Something went wrong!");
      }
    },
  },
  computed: {
    getPosts() {
      return this.$store.getters["cleaner/getPosts"];
    },
  },
};
</script>
