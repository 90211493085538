<template>
  <div class="border-2 rounded-xl bg-lightGrey my-2 w-11.5/12 m-auto h-14">
    <ul
      class="flex justify-between h-full w-full flex-wrap content-center mx-1"
    >
      <li class="w-2/6 text-left pl-5">
        <router-link :to="{ name: 'cs-room', params: { roomId: roomData.id } }">
          {{ roomData.name }}
        </router-link>
      </li>
      <li
        class="w-2/6 text-center"
        v-for="checklist in roomData.checklists"
        :key="checklist.id"
      >
        {{ checklist.name }}
      </li>

      <li v-if="roomData.status" class="w-2/6 text-right pr-5">
        <span class="border bg-lightGreen rounded-3xl px-2 text-white"
          >Done</span
        >
      </li>
      <li v-else class="w-2/6 text-right pr-5">
        <span class="border bg-red rounded-3xl px-2 text-white">Not Done</span>
      </li>
      <!-- <span class="material-icons text-red">
            report
          </span> -->
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    roomData: {
      type: Object,
      required: true,
    },
  },
};
</script>
